var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "audio-settings-panel px-3 mt-2" },
    [
      _c(
        "v-btn",
        {
          staticClass: "pl-1 ml-0 mb-3 mt-1",
          attrs: { flat: "" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.$emit("back")
            },
          },
        },
        [
          _c("v-icon", [_vm._v(" keyboard_arrow_left ")]),
          _c("h2", { staticClass: "white--text" }, [_vm._v("Audio")]),
        ],
        1
      ),
      _c("validation-observer", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var invalid = ref.invalid
              return [
                _c("weve-text-field-with-validation", {
                  attrs: {
                    label: "Title",
                    rules: "required",
                    maxlength: "25",
                    required: "",
                    placeholder: "Type here...",
                    hint: "25 Characters Left",
                  },
                  model: {
                    value: _vm.local.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.local, "name", $$v)
                    },
                    expression: "local.name",
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _c(
        "v-layout",
        {
          staticClass: "my-2",
          attrs: { flex: "", "justify-space-between": "" },
        },
        [
          _c("span", { staticClass: "white--text" }, [_vm._v("Hotkey")]),
          _c(
            "div",
            { staticStyle: { width: "50px", "text-align": "center" } },
            [
              _c("validation-observer", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var invalid = ref.invalid
                      return [
                        _c("weve-text-field-with-validation", {
                          attrs: { limit: 1, maxlength: "1" },
                          model: {
                            value: _vm.local.keypress,
                            callback: function ($$v) {
                              _vm.$set(_vm.local, "keypress", $$v)
                            },
                            expression: "local.keypress",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "mt-2 mb-4 white--text" },
        [
          _c("p", { staticClass: "mb-3" }, [_vm._v("File volume")]),
          _c("vue-slider-component", {
            attrs: {
              "rail-style": { background: "#A3C0FF" },
              "process-style": { background: "#A3C0FF" },
            },
            model: {
              value: _vm.local.volume,
              callback: function ($$v) {
                _vm.$set(_vm.local, "volume", $$v)
              },
              expression: "local.volume",
            },
          }),
        ],
        1
      ),
      _c(
        "v-layout",
        {
          staticClass: "my-2",
          attrs: { flex: "", "justify-space-between": "" },
        },
        [
          _c("div", [
            _c("span", { staticClass: "white--text" }, [_vm._v("SFX")]),
            _c(
              "p",
              {
                staticClass: "mt-2 white--text",
                staticStyle: { "font-size": "10px", color: "#cbcfd4" },
              },
              [_vm._v(" When used, SFX will not stop other audio ")]
            ),
          ]),
          _c("v-switch", {
            staticStyle: { flex: "none" },
            model: {
              value: _vm.local.overlay,
              callback: function ($$v) {
                _vm.$set(_vm.local, "overlay", $$v)
              },
              expression: "local.overlay",
            },
          }),
        ],
        1
      ),
      _c(
        "v-btn",
        {
          staticClass: "font-weight-bold",
          staticStyle: { "font-size": "16px" },
          attrs: { block: "", round: "", color: "#FF4060" },
          on: { click: _vm.remove },
        },
        [_vm._v(" REMOVE SOUND ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }