



























































import { PropType } from "vue"
import { ValidationObserver } from "vee-validate"
import { debounce } from "lodash"

import VueSliderComponent from "vue-slider-component"
import { cloneDeep } from "lodash"

import { AudioEntry } from "@/modules/audio/types"

import { WeveTextField } from "@weve/ui"

import AudioFactory from "@/modules/audio/entities/AudioFactory"

import AudioMixin from "@/mixins/AudioMixin"

// @ts-ignore
import WeveTextFieldWithValidation from "@/components/WeveTextFieldWithValidation.vue"

export default {
  name: "AudioSettingsPanel",
  components: {
    WeveTextFieldWithValidation,
    WeveTextField,
    VueSliderComponent,
    ValidationObserver
  },
  mixins: [AudioMixin],
  data() {
    return {
      local: {} as AudioEntry
    }
  },
  created() {
    const value = cloneDeep(this.value)
    this.local = value?.id ? value : { ...AudioFactory.create(), ...value }
    this.$watch("local", debounce(this.save, 100), { deep: true })
  },
  computed: {
    collection() {
      return this.value?.collection
    }
  },
  props: {
    value: {
      type: Object as PropType<AudioEntry>
    }
  },
  methods: {
    save(): Promise<any> {
      return this.$_AudioMixin_update(this.local, this.collection)
    },
    async remove() {
      try {
        await this.$confirm({
          message: "Are you sure to remove this audio?",
          buttonColor: "danger"
        })
        await this.$_AudioMixin_delete(this.local?.id, this.collection)
        this.$emit("back")
      } catch (e) {
        console.log(e)
      }
    }
  }
}
